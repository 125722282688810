@import url('https://fonts.googleapis.com/css2?family=Play:wght@400;700&display=swap');

:root {
    --purple: #7D00A2;
    --green: #2D7D00;
    --red: #CE0000;
    --blue: #0467A4;
}

.purple {
    color: var(--purple);
    font-size: 15px;
}

.red {
    color: var(--red);
    font-size: 15px;
}

.green {
    color: var(--green);
    font-size: 15px;
}

.blue {
    color: var(--blue);
    font-size: 15px;
}

body {
    margin: 0;
    height: 100%;
    overscroll-behavior: contain;
}

.main {
    background-image: url(./images/textured-bg.png);
    background-size: cover;
    background-attachment: local;
    overflow-x: hidden;
    height: 100vh;
    width: 100vw;
    font-family: 'Play', sans-serif;
}

.store-is-close {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
}

.bold {
    font-weight: 700 !important;
}

.black {
    color: #000;
}

.line {
    width: 483px !important;
    height: 3px;
    background: transparent linear-gradient(270deg, #00000000 0%, #000000 19%, #303030 85%, #00000000 100%) 0% 0% no-repeat padding-box;
    margin: 0 auto;
}

.action {
    background-color: #000;
    border-radius: 14px;
    min-height: 50px;
    margin: 6px;
}

.action h2 {
    padding-top: 8px;
    text-align: center;
    margin-bottom: 0 !important;
}

.action-btn {
    min-height: 54px !important;
    width: 100%;
    margin-top: 2rem;
}

.cards h2 {
    padding-top: 42px;
    font-size: 28px;
    margin-bottom: 8px;
}

.logo-div {
    display: flex;
    justify-content: center;
}

.logo-div img {
    height: 120px;
    margin-top: 42px;
}

.start-button {
    background-image: url(./images/select-a-team.png);
    height: 200px;
    width: 100%;
}

.start {
    width: 100%;
    margin: 8rem 0 2rem 0;
}

.center-text {
    text-align: center;
}

.center {
    display: flex;
    align-items: center;
    justify-content: center;
}

h1 {
    color: white !important;
    font-weight: bold !important;
}

form {
    color: white;
}

.row-container {
    padding-left: 5%;
    padding-right: 5%;
}

#landing h1, #registration h1 {
    margin: 0.5em 0;
    font-size: 28px;
}

#landing {
    display: block;
}

#landing .action {
    padding-top: 5px;
}

#landing .col-md-10 {
    padding: 0 !important;
}

#landing .col-md-3 {
    padding: 8px !important;
}

#landing .action-btn {
    width: 60%;
    margin: auto;
    padding: 0;
    text-transform: uppercase;
    margin-top: 42px;
}

.modal-container {
    justify-content: center;
    align-items: center;
    display: flex;
}

input.inputEl,
select#alias {
    width: 100%;
    background: transparent;
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    height: 40px;
    padding: 10px 0px 10px 12px;
    color: white;
}

input:focus,
input:hover,
select#alias:focus,
select#alias:hover {
    outline: none;
    border: 1px solid #de4a09;
    background: rgba(255, 255, 255, 0.1);
}

select#alias {
    padding-top: 7px !important;
}

select#alias option {
    color: black;
}

#checkbox:checked:after {
    background: red;
}

#checkbox:checked {
    background-color: #a77e2d !important;
    color: #ffffff !important;
}

.left-in {
    display: flex;
    flex-flow: column;
    margin: 30px;
    padding-top: 5%;
}

.left-in-2 {
    display: flex;
    z-index: 9;
}

.left-in-2-img1 {
    max-height: 45px;
    border-radius: 50%;
}

.left-in-2-img2 {
    max-height: 35px;
    margin-left: 15%;
}

.left-in-3 {
    margin-top: -55px;
}

.left-in-3-1 {
    border-radius: 50%;
    max-width: 460px;
    border: 1px dotted red;
    margin: 10px;
}

.left-in-3-img1 {
    border-radius: 50%;
    max-width: 460px;
    padding: 10px;
    width: 100%;
    height: 450px;
}

.left-in-3-img2 {
    height: 75px;
    position: relative;
    top: -90px;
    left: 50%;
}

.right-in {
    margin: 10px;
}

.right-in-1 {
    height: 85px;
    background-repeat: no-repeat;
    padding-top: 17px;
}

.f-name {
    margin: 2px auto auto 5px;
}

.button-div-30 {
    margin-top: 30px;
}

.right-img {
    float: right;
}

.z-1 {
    z-index: 1;
}

.subscribe {
    font-weight: bolder;
    text-decoration: none;
    border-bottom: 1px solid #de4a09;
    color: #de4a09;
    margin-left: 7px;
    display: contents;
    cursor: pointer;
}

.subscribe p {
    cursor: pointer;

    text-align: center;
    padding: 1em 0;
    margin-top: 7px;
    margin-left: 10px;
    text-transform: uppercase;
    font-size: 24px;
}

.disable{
    opacity: 50%;
}

.btn-disable {
    width: 100%;
    background-color: #999999;
    border-radius: 10px;
    border: none;
    cursor: pointer;
    outline: none;
    height: 40px;
    color: white;
}

.m-top-7 {
    margin-top: 7px;
}

.custom-m {
    margin: 2px auto auto 5px;
}

.black-background {
    position: absolute;
    z-index: 9999;
    background-color: rgba(0, 0, 0, 0.9);
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    height: 100vh;
}

.modal-box {
    z-index: 99999;
    max-width: 656px;
    min-width: 320px;
    width: 56%;
    height: 397px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background: rgba(0, 0, 0, 0.9);
    box-sizing: border-box;
    border-radius: 10px;
    font-weight: 700;
    text-transform: uppercase;
}

.modal-box .action h2 {
    padding-top: 12px;
}

.modal-box .action-btn {
    width: 65%;
    height: 62px;
}

.modal-box p {
    font-size: 16px !important;
    margin-bottom: 0;
}

#signup {
    padding-bottom: 3em;
}

#start h2 {
    color: white;
    font-size: 17px;
}

#start .col-md-6 {
    margin-top: 128px;
}

#start .action-btn {
    min-height: 125px !important;
    padding-top: 1px;
}

#start .action {
    min-height: 113px;
}

#start .action h2 {
    font-size: 45px;
    color: #FFC400;
    padding-top: 44px;
}

.emoji {
    height: 100px;
    width: 100px;
}

.emoji-div {
    width: 100%;
    text-align: center;
}

.headset-label {
    margin-top: 7px;
}

.p-absolute {
    position: absolute;
}

.modal-text {
    color: white;
    position: relative;
    top: 10%;
    text-align: center;
    font-size: 24px;
}

.button-div {
    padding: 10px;
    max-width: 413px;
    width: 100%;
}

.button-div button {
    outline: none;
    border: none;
    border-radius: 10px;
    background: #de4a09;
    box-shadow: 0px 4px 27px rgb(0 0 0 / 5%);
    color: white;
    height: 43px;
    max-width: 413px;
    width: 100%;
}

.react-multiple-carousel__arrow:hover,
.react-multiple-carousel__arrow {
    background: rgba(0, 0, 0, 0) !important;
}

.react-multiple-carousel__arrow.react-multiple-carousel__arrow--left {
    left: -15px;
}

.react-multiple-carousel__arrow.react-multiple-carousel__arrow--right {
    right: -15px;
}

.text-center .d-flex.flex-column {
    align-items: center;
}

.slider-div {
    padding-left: 30px;
    padding-right: 30px;
    padding-top: 3px;
    padding-bottom: 10px;
    box-sizing: border-box;
}

.slider-div img {
    border-radius: 10px;
    z-index: 10;
}

.right-arrow img {
    height: 75px;
}

.left-arrow {
    position: absolute;
    left: -5%;
    padding-left: 30px;
    top: 0;
    padding-top: 113px;
}

.right-arrow {
    position: absolute;
    right: -7%;
    padding-right: 30px;
    top: 0;
    padding-top: 113px;
}

.left-arrow img {
    height: 75px;
}


h1,
h2,
h3,
h4,
h5,
h6 {
    color: white;
}

.headsets.ant-radio-group.ant-radio-group-solid label {
    border-radius: 0px !important;
    max-width: 50px !important;
    margin-left: 20px !important;
    color: white;
    border-color: green !important;
}

.ant-radio-group.ant-radio-group-solid label {
    width: 100%;
    max-width: 100px;
    margin-top: 4px;
    margin-bottom: 4px;
    box-shadow: 0px 4px 27px rgba(0, 0, 0, 0.05);
    border-radius: 10px;
    border: 1px solid;
}

.headsets.ant-radio-group-solid .ant-radio-button-wrapper-checked {
    color: black !important;
    margin: 8px auto;
    background-color: #de4a09 !important;
    border-color: #de4a09 !important;
}

.ant-radio-group-solid .ant-radio-button-wrapper-checked {
    color: #fff !important;
    background-color: #de4a09 !important;
    margin: 10px auto;
    border-color: #de4a09 !important;
}

.ant-radio-button-wrapper:not(:first-child)::before {
    background-color: transparent !important;
}

.ant-radio-button-wrapper:hover {
    color: white !important;
    background-color: #de4a09 !important;
    border-color: #de4a09 !important;
}

.ant-radio-button-wrapper {
    background: transparent !important;
    color: rgba(255, 255, 255, 0.5) !important;
}

.slide {
    background-color: black;
    border-radius: 10px;
    padding-bottom: 5px;
    font-weight: 700;
}

.slider label {
    height: 190px;
    width: 100%;
    padding: 117% 0 0 0;
    font-size: 14px;
}

.slider .label-box {
    height: 250px;
    margin: 10px;
    align-items: center !important;
    display: flex !important;
    justify-content: center;
    width: 85%;
}

.slider .label {
    background-repeat: no-repeat;
    background-size: cover;
    height: 150px;
    width: 85%;
    background-position: center;
    border-radius: 10px;
    z-index: 1000;
}

.avatar {
    height: 215px;
    margin-top: -157px;
    width: 112px;
    object-fit: contain;
}

.slider input[type="radio"] {
    opacity: 0;
    position: fixed;
    width: 0;
}

.slider button {
    border: none;
    background-color: black;
    border-radius: 10px;
}

.slider-active-red {
    border: 6px solid var(--red);
}

.slider-active-blue {
    border: 6px solid var(--blue);
}

.slider-active-green {
    border: 6px solid var(--green);
}

.slider-active-purple {
    border: 6px solid var(--purple);
}

.slider-inactive {
    border: 1px solid transparent;
}

.slider .selected-green  {
    border: 7px solid var(--green);
}

.slider .selected-purple  {
    border: 7px solid var(--purple);
}

.slider .selected-red  {
    border: 7px solid var(--red);
}

.slider .selected-blue  {
    border: 7px solid var(--blue);
}

.slider .selected-green label, .slider .selected-purple label, .slider .selected-red label, .slider .selected-blue label {
    padding-top: 125%;
}

.upper-arrow {
    text-align: center;
    margin-top: -9px;
    /* margin-left: 23px; */
    margin-bottom: 10px;
    cursor: pointer;
}

.down-arrow {
    text-align: center;
    cursor: pointer;
}

.upper-arrow:focus,
.upper-arrow:hover {
    color: #de4a09;
}

.down-arrow:focus,
.down-arrow:hover {
    color: #de4a09;
}

.timer {
    text-align: center;
}

.arrow {
    height: 100%;
}

.right {
    right: 0;
}

.radio-slot {
    display: flex;
    max-width: 100px;
    flex-direction: column;
}

@media (max-width: 575px) {
    .timer {
        margin-top: 30px;
    }
}

.no-available-slot {
    height: 100%;
    justify-content: center;
    text-align: center;
    display: flex;
    align-items: center;
    font-weight: bold;
}

@media (max-width: 575px) {
    .no-available-slot {
        padding-top: 10%;
    }
}


/* ----------------- landing page css----------------- */


/* ------------------ New CSs----------------- */

:root {
    --screen: #052645;
    --card-1: #de4a09;
    --card: #ffffff11;
    --slots: #33d072;
    --clock: #de4a09;
    --booked: #f54e4e;
    --available: #33d072;
}

.bg-card {
    border-radius: 14px !important;
    background: linear-gradient(30deg, rgba(225,74,80,1) 0%, rgba(255,196,0,1) 100%);
    box-shadow: 0px 3px 56px #00000029;
    margin: 42px 0;
}

.card {
    border: none !important;
    height: 165px !important;
}

.bg-card2 {
    background-color: grey !important;
}

.bg-card-blue .card-body {
    background-color: #023452 !important;
}

.bg-card-red .card-body {
    background-color: #811414 !important;
}

.bg-card-green .card-body {
    background-color: #264b11 !important;
}

.bg-card-purple .card-body {
    background-color: #3b1447 !important;
}

.bg-card-d {
    height: 100%;
    width: 100%;
    min-height: 11rem;
}

#purple-card {
    border-bottom: 16px solid var(--purple);
    width: 100%;
    border-radius: 0 0 14px 14px;
    height: 100%;
}

#red-card {
    border-bottom: 16px solid var(--red);
    width: 100%;
    border-radius: 0 0 14px 14px;
    height: 100%;
}

#green-card {
    border-bottom: 16px solid var(--green);
    width: 100%;
    border-radius: 0 0 14px 14px;
    height: 100%;
}

#blue-card {
    border-bottom: 16px solid var(--blue);
    width: 100%;
    border-radius: 0 0 14px 14px;
    height: 100%;
}

.card-body {
    position: relative;
    margin: 6px;
    background-color: #000;
    border-radius: 14px;
    height: 116px !important;
    padding: 0 !important;
    text-transform: uppercase;
    font-weight: 700;
    height: 100%;
}

.btnrow {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.yesno {
    width: 40% !important;
}

#registration .restart {
    margin-top: 35px;
}

#landing .restart {
    margin-top: 84px;
}

.slots-remaining {
    color: #FFC400;
    padding-top: 5px;
    font-size: 11px;
}

.restart h3 {
    color: white;
    font-weight: 700;
    text-transform: uppercase;
    font-size: 20px;
    margin-top: -7px;
}

.slots-qty {
    position: absolute;
    top: -10%;
    left: 5%;
    right: 5%;
    background-color: #000 !important;
} 

.slots-qty1 {
    position: absolute;
    top: -10%;
    left: 5%;
    right: 5%;
    background-color: red !important;
}

.fa-clock {
    color: #de4a09;
}

.booked {
    background-color: #f54e4e !important;
}

.available {
    background-color: #33d072 !important;
}

@media (max-width: 1200px) {
    * {
        font-size: 14px;
    }
}

@media (max-width: 800px) {
    * {
        font-size: 25px;
    }
}

@media (min-width: 768px) and (max-width: 800px) {

  .user-acount-option .col-md-2 {
    padding: 0;
    margin-right: 30px;
    width: 19%;
  }

  .user-acount-option .col-md-3 {
width: 20%;
}
}

@media (max-width: 750px) {
    * {
        font-size: 25px;
    }
}


/* ------------------- new css ------------------ */

.statsBox {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
}

.gameStartTime {
    width: 50%;
    box-shadow: 0px 4px 27px rgb(0 0 0 / 5%);
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    max-height: 98px;
    height: 98px;
    margin-right: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.gameStartTime h5 {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
}

.HeadshotsNumbers {
    width: 50%;
    box-shadow: 0px 4px 27px rgb(0 0 0 / 5%);
    background: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(255, 255, 255, 0.3);
    box-sizing: border-box;
    border-radius: 10px;
    padding: 10px;
    max-height: 98px;
    height: 98px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.HeadshotsNumbers h5 {
    font-size: 18px;
    color: #fff;
    text-align: center;
    margin-bottom: 10px;
}

.gameStartTime svg,
.HeadshotsNumbers svg {
    margin-right: 16px;
}

.HeadshotsNumbers span.number {
    color: #de4a09;
    font-size: 32px;
    line-height: 18px;
    font-weight: 700;
}

.container .slotsContainer {
    border: 1px solid #969696;
    padding: 60px 60px !important;
    border-radius: 10px !important;
    padding: 60px !important;
}

.slotContainer {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    margin: 0px !important;
    padding: 40px 20px;
    display: flex !important;
    align-items: center;
    flex-direction: row !important;
    flex-wrap: wrap !important;
}

.slotContainer-start {
    background: rgba(0, 0, 0, 0.1);
    border: 1px solid rgba(255, 255, 255, 0.25);
    border-radius: 15px;
    margin: 0px !important;
    padding: 40px 20px;
    display: flex !important;
    align-items: center;
    flex-direction: row !important;
    flex-wrap: wrap !important;
}

.slotContainer .col-md-2 {
    width: 12.5%;
}

.slotContainer-start .col-md-2 {
    width: 12.5%;
}

.cursor {
    cursor: pointer;
}

.remove-radio-properties {
    all: initial !important;
}

.card-text {
    padding-top: 5px;
}

.error {
    color: red;
}

.loading-gif {
    border-radius: 50%;
}

.loading {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 35%;
}

.reg {
    /* position: absolute; */
    /* width: 180px; */
    /* height: 29px; */
    left: 390px;
    top: 211px;
    font-family: Play; 
    /* font-style: normal; */
    font-weight: bolder; 
    /* font-size: 25px; */
    line-height: 29px;
    /* identical to box height */
    color: #FFFFFF ! important;
    margin-bottom: 60px;
    /* font-weight: 800; */
}

.queue-name, .queue-name-default {
    font-family: Play;
    font-weight: bold;
    line-height: 25px;
    color: #FFC400;
    font-size: 25px;
}

.queue-name-hover {
    font-family: Play;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 25px;
    color: white;
}

.queue-time {
    font-family: Play; 
    font-size: 15px;
    line-height: 17px;
    color: #FFF;
}

.queue-time-d {
    font-family: Play; 
    font-style: revert;
    /* font-weight: normal; */
    font-size: 20px;
    line-height: 17px;
    color: #FFFFFF;
    font-weight: bold;
    padding-top: 8px !important;
}

.restart img {
    width: 28px;
    margin-top: -6px;
    margin-right: 4px;
}

.avl-headset {
    font-family: Play;
    font-style: normal;
    font-weight: bold;
    font-size: 12px;
    line-height: 14px;
    /* identical to box height */
    color: #FFFFFF;
}

.bg-card-button {
    background-color: #ffffff11 !important;
    min-height: 8rem;
    width: 120px;
    border-radius: 0px !important;
    /* border: 1px solid #fff !important; */
}

.play-as-guest {
    width: 60px;
    display: block;
    margin: auto;
    /* margin-left: 23px; */
    /* margin-top: 10px; */
}

.sign-up {
    width: 60px;
    /* margin-left: 23px; */
    /* margin-top: 9px; */
    display: block;
    margin: auto;
}

.login {
    width: 60px;
    /* margin-left: 23px; */
    /* margin-top: 10px; */
    display: block;
    margin: auto;
}

.sign-up-text {
    /* margin-top: 5px; */
    /* margin-left: 27px; */
    display: block;
    margin: auto;
    font-weight: bolder;
}

.login-text {
    /* margin-top: 12px; */
    /* margin-left: 35px; */
    display: block;
    margin: auto;
    font-weight: bolder;
}

.play-as-guest-text {
    /* margin-top: 10px; */
    /* margin-left: 8px; */
    display: block;
    margin: auto;
    font-weight: bolder;
}

.start-img {
    width: 100px;
}

.start-img-new {
    width: 100px;
}

#RegisterForm {
    margin-top: 21px;
}

#registration video {
    border-radius: 10px;
}

#registration .queue-time {
    color: #FFC400;
    font-size: 13px;
}

#registration .col-md-11 {
    margin: auto;
}

#registration button.sign-in {
    width: 70%;
    border: none;
    min-height: 55px;
    padding: 0;
    margin: 20px 0 0 0;
}

#registration .action-btn {
    margin-top: 0;
}

#registration button {
    outline: none;
}

#registration .center {
    flex-direction: column;
    width: 80%;
    margin: auto;
}

#registration .input-div {
    flex-grow: 1;
}

#registration .input-div:first-child {
    padding-right: 10px;
}

#registration .input-div:nth-child(2) {
    padding-left: 10px;
}

#registration input {
    background-color: white;
    height: 37px;
    margin-top: 8px;
    color: black;
    font-size: 20px;
}

#registration .sign-in .action {
    padding-top: 5px;
}

.carousel-button-group {
    position: absolute;
    top: 56%;
    left: 9%;
    width: 100%;
}

input.inputEl, select#alias {
    border-radius: 5px !important;
}

.sign-in-fields {
    background-color: rgba(0, 0, 0, 0.7);
    border-radius: 10px;
    padding: 14px 45px 18px 45px !important;
    margin-top: 1rem;
    flex-direction: row;
    display: flex;
}

.sign-in-fields span {
    font-size: 14px;;
}

#purple-border {
    border-bottom: 11px solid var(--purple);
}

#red-border {
    border-bottom: 11px solid var(--red);
}

#green-border {
    border-bottom: 11px solid var(--green);
}

#blue-border {
    border-bottom: 11px solid var(--blue);
}

@media screen and (min-device-width: 820px) and (max-device-width: 1180px) {
    .bg-card-button {
        background-color: #ffffff11 !important;
        min-height: 7rem;
        width: 90px;
        border-radius: 0px !important;
        /* border: 1px solid #fff !important; */
    }
    .play-as-guest {
        width: 40px !important;
        display: block;
        margin: auto;
    }
    .play-as-guest-text {
        /* margin-top: 10px; */
        /* margin-left: 0px; */
        display: block;
        margin: auto;
        font-weight: 500;
    }
    .sign-up {
        width: 50px;
        display: block;
        margin: auto;
    }
    .login {
        width: 50px;
        display: block;
        margin: auto;
    }
    .sign-up-text {
        /* margin-top: 10px; */
        /* margin-left: 15px; */
        display: block;
        margin: auto;
        font-weight: 500;
    }
    .login-text {
        /* margin-top: 20px; */
        /* margin-left: 22px; */
        display: block;
        margin: auto;
        font-weight: 500;
    }
}

@media screen and (min-device-width: 768px) and (max-device-width: 1024px) {
    .bg-card-button {
        background-color: #ffffff11 !important;
        min-height: 7rem;
        width: 90px;
        border-radius: 0px !important;
        /* border: 1px solid #fff !important; */
    }
    .play-as-guest {
        width: 55px;
        display: block;
        margin: auto;
    }
    .play-as-guest-text {
        /* margin-top: 9px; */
        /* margin-left: 8px; */
        display: block;
        margin: auto;
        font-weight: 500;
    }
    .sign-up {
        width: 50px;
        display: block;
        margin: auto;
    }
    .login {
        width: 50px;
        display: block;
        margin: auto;
    }
    .sign-up-text {
        /* margin-top: 9px; */
        /* margin-left: 19px; */
        display: block;
        margin: auto;
        font-weight: 500;
    }
    .login-text {
        /* margin-top: 20px; */
        /* margin-left: 26px; */
        display: block;
        margin: auto;
        font-weight: 500;
    }
}

@media screen and (orientation: portrait) {
    .main {
      transform: rotate(-90deg);
      width: 100vh;
      background-size: 100% 75%;
    }
    .start {
        margin: 5rem 0 2rem 0;
    }
    #landing .col-md-10 {
        width: 100%;
        margin-left: 0;
    }
    .cards h2 {
        margin-bottom: 0;
    }
    .slots-remaining {
        padding-top: 0;
    }
    .modal-box {
        top: 38%;
    }
    .sign-in-fields {
        margin-left: 0;
    }
    #registration .col-md-11 {
        width: 114%;
    }
    #registration .col-md-10 {
        margin-left: 0;
    }
    #registration .restart {
        margin-top: 25px;
    }
    .slider label {
        padding: 124% 0 0 0;
    }
    .slider .selected-green label, .slider .selected-purple label, .slider .selected-red label, .slider .selected-blue label {
        padding-top: 139%; 
    }
  }


/* *********** sign in page ************** */

.logo-div img {
    height: 90px;
    margin: 70px 0 10px 0;
}

button {
    background-color: #000;
    border: 4px solid #F48B0E;
    border-radius: 2px !important;
    color: #F48B0E !important;
    font-weight: bold;
    text-transform: uppercase !important;
}

#signin h1 {
    margin: 35px 0 60px 0;
    font-size: 30px;
}

#signin .registered img {
    width: 240px;
}

#signin .registered {
    border-right: 7px solid #F48B0E;
    padding: 60px 50px 70px 0;
    text-align: center;
}

#signin .registered h4 {
    color: #E96130;
    font-weight: bold;
    text-transform: uppercase;
    font-size: 16px;
    margin-top: 60px;
}

#signin .registered button {
 padding:4px 7px 7px 7px;
 width: 60px;
}

#signin .registered button img {
    width: 10px;
    margin-left: 4px;
}

#signin .registered input {
    height: 40px;
    width: 200px;
    margin: 27px 13px 0 0;
    border: none;
}

#signin .guest-button {
    padding: 12px 35px;
    font-size: 25px !important;
}

#signin .play-guest {
    text-align: center;
    padding-left: 40px;
}

#signin .play-guest img {
    width: 220px;
    padding: 75px 0px 95px 0;
}


/* ******* check in page ****** */

#guest .checkalias {
    text-align: center;
    margin-left: 13% !important;
}

#guest .checkalias {
    background-color: rgba(0,0,0,.4);
    padding: 10px 0 20px 0;
}

#guest .checkalias label {
    color: #F48B0E;
    text-transform: uppercase;
    font-weight: bold;
    margin-bottom: 13px;
}

#guest .checkalias input {
    width: 400px;
    height: 48px;
    color:black;
    font-weight: bold;
    font-size: 24px;
    border: none;
    background-color: white;
    /*NOTE */
    /* Virginie - Please review to make sure this is not a breaking change*/
    /* padding: 0 100px 0 10px;  */ 
    text-align: center;
}

#guest .checkalias a {
    color: #fff;
    text-transform: uppercase;
    font-size: 20px;font-weight: bold;
    margin-top: 5px;
}

#guest .checkalias a img {
    width: 27px;
    margin-right: 8px;
    margin-top: -4px;
}

#submit-btn {
    width: 450px;
    margin: 30px 0;
    font-size: 25px;
    padding: 7px 0;
}

.center .restart {
    margin-top: 5px;
}

.slick-center {
    border: 4px solid #F59E24;
    opacity: 1 !important;
    height: 350px;
}

.slick-center .avatar {
    width: initial;
    animation: zoom .20s ease-out;
    height: 340px;
}

@keyframes zoom {
    from {height: 215px;}
    to {height: 340px;}
}

.slick-center > div {
    margin-top: 76px;
}

.slick-slide {
    opacity: .5;
}

#guest h1 {
    font-size: 30px;
    margin: 30px;
}

#carousel {
    margin-bottom: 30px;
}

.slick-track {
    display: flex;
    align-items: center;
}

.slick-list {
    z-index: 1;
}

.arrow {
    z-index: 10;
}

.keyboard-icon {
    position: absolute;
    width: 45px;
    margin: 10px 0 0 345px;;
}


/* ********* thank you ******* */

.thx {
    background-color: rgba(0,0,0,.8);
    border-radius: 2px;
    padding: 80px 0 40px 0;
    margin-top: 45%;
}

.thx h1 {
    color: #E07F0C !important;
    font-size: 30px !important;
    margin-top: 50px !important;
}

.thx img {
    width: 120px;
}

/* ************** *************** */

@media (max-width: 1200px) { 
    #signin h1, #guest h1 {
        margin: 25px 0 40px 0;
        font-size: 25px;
    }
    #guest h1 {
        margin: 14px 0 27px 0;
    }
    #signin .registered {
        padding: 45px 50px 34px 0;
    }
    #signin .play-guest img {
        padding: 58px 0px 95px 0;
    }
    .logo-div img {
        margin: 35px 0 10px 0;
    }

    .avatar {
        height: 155px;
    }
    .slick-center .avatar {
        height: 270px
    }
    .slick-center > div {
        margin-top: 10px;
    }
    .slick-center {
        height: 280px;
    }
    #guest .checkalias input {
        height: 40px;
        width: 352px;
        padding: 0 47px 0 10px;
    }
    .keyboard-icon {
        margin: 9px 0 0 309px;
        width: 36px;
    }
    #RegisterForm {
        margin-top: 0;
    }
    #submit-btn {
        margin: 20px 0;
        width: 412px;
        font-size: 21px;
    }
    .restart h3 {
        font-size: 17px;
    }
    .restart img {
        width: 21px;
    }
    #guest .checkalias {
        padding: 6px 0 15px 0;
    }
    #guest .checkalias label {
        font-size: 12px;
        margin-bottom: 6px;
    }
    .left-arrow img, .right-arrow img {
        height: 57px;
    }
    .thx {
        margin-top: 32%;
    }
}


@media (max-height: 850px) {
    .logo-div img {
        margin: 40px 0 10px 0;
        height: 60px;
    }
    #signin h1 {
        margin: 25px 0 40px 0;
        font-size: 18px;
    }
    #signin .registered {
        padding: 25px 50px 40px 0;
    }
    #signin .play-guest img {
        padding: 47px 0px 73px 0;
    }
    #signin .registered h4 {
        margin-top: 45px;
    }

    #guest h1 {
        margin: 10px 0 0 0;
        font-size: 18px;
    }
    #carousel {
        margin-bottom: 5px;
    }
    #submit-btn {
        margin: 15px 0;
        font-size: 19px;
        width: 405px;
    }
    #guest .checkalias input {
        width: 345px;
        height: 41px;
        font-size: 19px;
    }
    .queue-time {
        font-size: 14px;
        margin-bottom: 5px !important;
    }
    #guest .checkalias {
        padding: 2px 0 12px 0;
    }
    .restart h3 {
        font-size: 16px;
    }
    .keyboard-icon {
        width: 37px;
        margin: 9px 0 0 299px;
    }
    .restart img {
        width: 21px;
        margin-top: 0;
    }

    .thx {
        margin-top: 27%;
    }
}